.menuHolder {
  overflow-y       : hidden;
  overflow-x       : hidden;
  background-color : #F8F9FA;
  width            : 100%;

  .menu {
    justify-content : start;
    flex-wrap       : nowrap;
    display         : flex;
    height          : 80px;
    max-width       : 100%;
    margin          : auto;

    .holder {
      flex-wrap : nowrap;
      display   : flex;
      height    : 80px;
      cursor    : pointer;

      &.visited {
        .stepNumberHolder {
          .stepNumber {
            background-color : #E30713;
            color            : white;
            border           : 1px solid #E30713;

            &:after {
              content          : '✓';
              position         : absolute;
              background-color : #E30713;
              color            : white;
              border           : 1px solid #E30713;
              border-radius    : 50%;
              height           : 35px;
              width            : 35px;
              display          : flex;
              justify-content  : center;
              align-items      : center;
              font-size        : 18px;
            }
          }
        }

        .stepTitleHolder {
          .stepTitle {
            color : #E30713;
          }
        }
      }

      &.active {
        .stepNumberHolder {
          .stepNumber {
            background-color : #E30713;
            color            : white;
            border           : 1px solid #E30713;

            &:after {
              opacity : 0;
            }
          }
        }

        .stepTitleHolder {
          .stepTitle {
            color : #E30713;
          }
        }
      }

      .triangle {
        width         : 0;
        height        : 0;
        border-top    : 40px solid transparent;
        border-bottom : 40px solid transparent;
        border-left   : 40px solid white;
        position      : relative;

        &:after {
          content       : '';
          width         : 0;
          height        : 0;
          border-top    : 40px solid transparent;
          border-bottom : 40px solid transparent;
          border-left   : 40px solid #F8F9FA;
          position      : absolute;
          top           : -40px;
          left          : -45px;
        }
      }

      .stepNumberHolder {
        display          : flex;
        justify-content  : center;
        align-items      : center;
        flex-wrap        : nowrap;
        position         : relative;
        background-color : #F8F9FA;
        margin           : 0 40px;
        width            : 65px;
        max-width        : 100%;

        .stepNumber {
          border          : 1px solid #282c34;
          border-radius   : 50%;
          height          : 35px;
          width           : 35px;
          display         : flex;
          justify-content : center;
          align-items     : center;
          font-size       : 18px;
        }

        .stepTitleHolder {
          display: none;
          margin-left : 15px;

          .stepTitle {
            font-size   : 14px;
            font-weight : bold;
          }

          .stepDesc {
            font-style : italic;
            font-size  : 12px;
            color      : #444;
          }
        }

      }
    }
  }
}
