
.paddingTop {
  padding-top : 50px;
}

.paddingBottom {
  padding-bottom : 50px;
}

.grayBg {
  background-color : #f8f9fa;
  padding          : 25px;
}

.noBg {
  padding : 25px;
}

.maxWidth {
  width : 100%;
}

.textRed {
  color : #e2001a;
}

.calcTitle {
  color          : #e2001a;
  font-family    : "Arial", 'serif';
  font-weight    : 700;
  line-height    : 1.2em !important;
  font-size      : 32px !important;
  padding-bottom : 20px;
  text-align     : left;
}

.calcSub {
  font-family : "Arial", 'serif';
  color       : #444;
  font-weight : 700;
  font-size   : 16px !important;
}

.title {
  font-family    : "Arial", 'serif';
  color          : black;
  font-weight    : 700;
  line-height    : 1.2em !important;
  font-size      : 32px !important;
  padding-bottom : 20px;
}

.subtitle {
  font-family    : "Arial", 'serif';
  color          : #444;
  font-weight    : 700;
  font-size      : 16px !important;
  text-transform : uppercase;
}

.subtitleRed {
  font-family    : "Arial", 'serif';
  color          : #e2001a;
  font-weight    : 700;
  font-size      : 22px !important;
  padding-bottom : 10px;
}

.result {

  padding-left : 20px;

  > li {
	list-style : none;
  }
}

.result > li::before {
  content          : "";
  display          : inline-block;
  width            : 10px;
  height           : 10px;
  background-color : #e30613;
  margin-left      : -20px;
  margin-right     : 10px;
}

.circleHolder {
  text-align      : center;
  padding-bottom  : 40px;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;

  .circleTitle {
	color       : #282c34;
	font-family : "Arial", 'serif';
	font-size   : 28px !important;
	font-weight : 700;

	@media (max-width : 556px) {
	  font-size : 22px !important;
	}
	@media (max-width : 400px) {
	  font-size : 18px !important;
	}

	&.red {
	  color : #e30613;
	}
  }

  .chartHolder {
	padding-top : 25px;

	.circleValue {
	  font-size : 28px;

	  &.red {
		color : #e30613;
	  }

	  span {
		display   : block;
		font-size : 18px;
		color     : #282c34;
	  }
	}
  }

}


.hiddenRefChartHolder {
  opacity        : 0;
  pointer-events : none;
  overflow       : hidden;
  position       : relative;

  .hiddenRefChart {
	position : absolute;
	left     : 0;
	top      : 4000px;

	.chartSize {
	  width  : 590px !important;
	  height : 300px !important;
	}
  }
}

.copyToClipboard {
  background-color        : rgba(222, 222, 222, 0.85);
  color                   : #2e2e2e;
  padding                 : 10px 20px;
  border-radius           : 32px;
  display                 : -webkit-flex;
  display                 : flex;
  -webkit-justify-content : space-between;
  justify-content         : space-between;
  -webkit-align-items     : center;
  align-items             : center;
  text-align              : center;
  cursor                  : pointer;
  transition              : all .3s ease-in-out;

  &.copied {
	transition : .3s;
	color      : #e30613;
  }


}

.caption {
  font-family    : "Arial", 'serif';
  color          : #444;
  font-weight    : 700;
  font-size      : 12px !important;
  text-transform : uppercase;
}

.dontShowMe {
  @media(max-width : 1200px) {
	display : none;
  }
}
