.rdt {
  color : #222222;

  input {
	border-radius    : unset;
	background-color : transparent;
	border           : unset;
	border-bottom    : 2px solid #222222;
	color            : #222222;
	padding-left     : unset;
	font-size        : 1.25em;
	font-weight      : bold;

	&:active, &:focus, &:focus-visible {
	  border           : inherit;
	  border-bottom    : 2px solid #222222;
	  background-color : inherit;
	  color            : #222222;
	  box-shadow       : unset;
	  outline          : none;
	}
  }
}
