.pb5 {
  padding-bottom : 2em;
}

.title {
  font-family : "Arial", 'serif';
  color       : #E30713;
  font-weight : 700;
  line-height : 1.2em !important;
  font-size   : 32px !important;
  word-break  : break-word;
}

.label {
  font-size : 16px;
}

.subtitle {
  font-family    : "Arial", 'serif';
  color          : #444;
  font-weight    : 700;
  font-size      : 16px !important;
  text-transform : uppercase;
}

.priceTitle {
  font-family    : "Arial", 'serif';
  color          : #444;
  font-weight    : 700;
  font-size      : 16px !important;
  text-transform : none;
}

.caption {
  margin-top     : 100px;
  color          : #444;
  font-family    : "Arial", Helvetica, Verdana, sans-serif;
  font-size      : 16px;
  font-weight    : 400;
  line-height    : 1.6;
  word-wrap      : break-word;
  padding-bottom : 40px;
}

.heizkostenHolder {
  padding-top    : 3em;
  padding-bottom : 3em;
}

.buttonRow {
  padding         : 2em;
  display         : flex;
  justify-content : center;
  align-items     : center;

  button {
    width : 100%;
  }
}

.personen {
  @media(max-width : 556px) {
    display : none;
  }
}

.unit{

}

.errorMsg {
  color : #E30713;
}

.errorMsgLeft {
  color      : #E30713;
  text-align : left;
}

.errorMsgBtn {
  color          : #E30713;
  font-size      : 18px;
  padding-bottom : 15px;
  display        : inline-block;
}

.step {
  text-align : center;

  .rangeSliderInput {

    input {
      border-radius    : unset;
      background-color : transparent;
      border           : unset;
      border-bottom    : 2px solid #E30713;
      color            : #E30713;
      padding-left     : unset;
      font-size        : 1.25em;
      font-weight      : bold;

      &:active {
        border           : inherit;
        border-bottom    : 2px solid #E30713;
        color            : #E30713;
        background-color : inherit;
        box-shadow       : unset;
      }

      &:focus {
        border           : inherit;
        border-bottom    : 2px solid #E30713;
        color            : #E30713;
        background-color : inherit;
        box-shadow       : unset;
      }
    }

    span {
      background-color : transparent;
      border           : none;
      border-bottom    : 2px solid #E30713;
      color            : #E30713;
      border-radius    : 0;
    }
  }


  .choiceTitle {
    text-align : center;
  }

  .choice {
    text-align         : center;
    text-transform     : none;
    font-weight        : bold;
    color              : #000;
    font-size          : 20px;
    -webkit-transition : 0.2s ease-out;
    transition         : 0.2s ease-out;
    padding            : 30px;
    max-width          : 100%;
    width              : 100%;
    min-height         : 200px;
    align-items        : center;
    display            : flex;
    justify-content    : center;
    flex-direction     : column;
    background-color   : #F8F9FA;
    border-bottom      : 4px solid transparent;
    margin-bottom      : 0;

    @media(max-width : 767px) {
      margin : 15px 0;
    }

    &:hover {
      color         : #E30613;
      cursor        : pointer;
      border-bottom : 4px solid #E30613;
    }

    &.active {
      color            : #E30613;
      background-color : #E4E6E7;
      border-bottom    : 4px solid #E30613;
    }
  }
}

.buttonHolder {
  display         : flex;
  justify-content : center;
  align-items     : center;
  padding-top     : 50px;

  @media(max-width : 767px) {
    padding-top : 25px;
  }

  .button {
    display             : inline-block;
    margin-bottom       : 0;
    font-weight         : bold;
    line-height         : 1.42857143;
    text-align          : center;
    white-space         : nowrap;
    vertical-align      : middle;
    -ms-touch-action    : manipulation;
    touch-action        : manipulation;
    cursor              : pointer;
    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none;
    background-image    : none;
    border-radius       : 0px;
    transition          : 0.2s;
    text-transform      : uppercase;
    font-size           : 14px;
    padding             : 12px 50px;
    background-color    : #818285;
    color               : #FFF;
    border              : 2px solid #818285;
    min-width           : 275px;
    width               : 100%;

    &.nextButton {
      background-color : #E30613;
      color            : #FFF;
      border           : 2px solid #E30613;
      text-decoration  : none;

      &:focus {
        box-shadow : unset !important;
      }

      &:hover {
        transform : scale(1.05);
      }
    }

    &.prevButton {
      background-color : transparent;
      color            : #444 !important;
      border           : 2px solid #444;
      padding          : 12px 25px;

      &:hover {
        background-color : #444;
        color            : #FFF !important;
        border           : 2px solid #444;
      }
    }
  }
}
