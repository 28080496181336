@import "../../Theme/variables.scss";

input[type=range].range-slider.range-slider--danger::-webkit-slider-thumb {
  background: $darkgray;
}

input[type=range].range-slider.range-slider--info::-webkit-slider-thumb {
  background: $red;
}

.range-slider__wrap:hover .range-slider__tooltip:not(.range-slider__tooltip--off):not(.range-slider__tooltip--disabled) {
  opacity:1;
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  background-color: #fff;
  color: #222222;
  padding: 0.5em 1em;
  height: 39px;
  opacity: 1;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__caret {
  opacity: 1;
  &:before {
    border-bottom-color: #fff;
  }
}
